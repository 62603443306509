import React from "react";
import "../ContactUs/styles/index.css";    
import { Card, Typography } from "@mui/material";
import Group from "../../assets/Mainlogo/Group.png";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import EmailIcon from '@mui/icons-material/Email';

const Contact = () => {
  return (
    <>
      <Card className="contact-page" style={{ backgroundColor: "#FFA433" }}>
        <div className="contact-box">
          <img src={Group} alt="" style={{ width: "200px" }} />
          <div className="media-links">
            <InstagramIcon style={{ width: "50px", height: "50px", color: "#FFFFFF" }} />
            <FacebookIcon style={{ width: "50px", height: "50px", color: "#FFFFFF" }} />
            <TwitterIcon style={{ width: "50px", height: "50px", color: "#FFFFFF" }} />
            <LinkedInIcon style={{ width: "50px", height: "50px", color: "#FFFFFF" }} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", height: "35vh" }}>
            <Typography variant="h5" style={{ fontWeight: "bold", fontSize: "30px", color: "#FFFFFF" }}>Our Major Selling Products</Typography>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>Smart Class Related Products</Typography>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>Computer Components</Typography>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>Playschool / Kindergarden Products</Typography>
          </div>

        </div>
        <div className="contact-box" >
          <Typography variant="h4" style={{ fontWeight: "bold", color: "#FFFFFF" }}>Company</Typography>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>E-Mart</Typography>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>Our Services</Typography>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>Our Portfolio</Typography>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>Star Products</Typography>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>Smart Class Materials</Typography>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>Get a quote</Typography>
        </div>
        <div className="contact-box">
          <Typography variant="h4" style={{ fontWeight: "bold", color: "#FFFFFF" }}>Address</Typography>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
            <LocationOnIcon style={{ width: "24px", height: "24px" }} />
            <Typography variant="h6" style={{ fontWeight: "bold", marginTop: "-5px", marginLeft: "10px" }}>No. 1/1, Nathan Street, Murugankurichi, Adaikalapuram, Palayamkottai, Tirunelveli - 627 002</Typography>
          </div>
          <div style={{ display: "flex" }} >
            <EmailIcon />
            <a href="mailto:support@ncpli.com" variant="h6" style={{ textAlign: "center", color: "#000000", textDecorationLine: "none", marginLeft: "10px", justifyContent: "center", fontWeight: "bold" }}>support@ncpli.com</a>
          </div>
          <div style={{ display: "flex" }} >
            <PhoneInTalkIcon />
            <a href="tel:+91 7305023014, +91 9791509522" variant="h6" style={{ fontWeight: "bold", textAlign: "center", color: "#000000", textDecorationLine: "none", marginLeft: "10px" }}>+91 7305023014,<br /><br /> +91 9791509522</a>
          </div>
        </div>
      </Card>
      <div className="copyright-section">
        <Typography variant="h5" style={{ fontWeight: "bold", padding: "16px" }}>©2023 | All rights reserved | Kevins Productions Pvt Ltd</Typography>
      </div>
    </>
  )
}

export default Contact;